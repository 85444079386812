import { Injectable } from '@angular/core'
import { SupabaseClient, createClient } from '@supabase/supabase-js'
import { environment } from 'environments/environment'
import { Database } from './database.types'

@Injectable({
  providedIn: 'root'
})
export class Api {
  public supabase: SupabaseClient<Database>

  constructor() {
    this.supabase = createClient(environment.SUPABASE_URL, environment.SUPABASE_KEY)
  }
}
